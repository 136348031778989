

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

input,
textarea {
  width: 100%;
  min-width: 300px;
  padding: 8px;
  margin-top: 4px;
}

textarea{
  columns: 8;
}

select {
  width: 100%;
  min-width: 300px;
  padding: 8px;
  margin-top: 4px;
}

button {
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
}