.hide {
    display: none;
}

td:hover .hide {
    display: block;
}

.max-line-2 {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
