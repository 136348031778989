.content-drawer {
    height: calc(100% - 64);
}

.active {
    background-color: var(--primary);
}

.item-channel {
    cursor: pointer;
}

.item-channel:hover {
    background-color: gainsboro;
}